<template>
  <v-layout wrap justify-center id="cf">
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <v-flex xs12 lg8 pt-6>
      <v-layout wrap>
        <v-flex>
          <v-card
            class="mx-auto elevation-1 pa-6"
            max-width="1088px"
            align="center"
            outlined
          >
          <v-layout wrap justify-center v-if="cloth">
              <v-flex xs12 v-if="cloth.length>0" px-6 pt-2>
                <!-- {{ mylist }} -->
                <!-- <v-card v-for="(item, i) in mylist" :key="i" elevation="0">
                    <v-layout>
                        <v-flex>{{item.clothid.clothType}}</v-flex>
                    </v-layout>
                </v-card> -->
                <v-flex xs12 text-right py-3>
                      <v-icon @click="$router.go(-1)" title="Back"
                    style="cursor: pointer;color:#766BC0"
                    >mdi-arrow-left</v-icon
                  >
                    </v-flex>
                <v-layout wrap>
                    <v-flex xs12 text-left lg1 class="nsbold" style="font-size:18px">
                        Type:
                    </v-flex>
                    <v-flex text-left class="nsregular" style="font-size:18px" pl-4xs12 lg4 align-self-center>
                       {{cloth[0].clothid.clothType.name}}
                    </v-flex>
                    <v-flex xs12 lg5 text-right class="nsbold" style="font-size:18px">
                        Barcode:
                    </v-flex>
                    <v-flex xs12 text-right lg2 class="nsregular" style="font-size:16px">
                       {{cloth[0].clothid.clothbarid}}
                    </v-flex>
                </v-layout>
                <v-card round style="background-color:#766BC0">
                  <v-layout wrap pa-2 class="nsbold" style="font-size:16px;color:#FFFFFF;">
                    <v-flex text-left xs2>Serial.No</v-flex>
                    <v-flex text-left xs5>Latest In</v-flex>
                    <v-flex text-left xs5>Latest Out</v-flex>
                    
                  </v-layout>
                </v-card>
                <v-card v-for="(item, i) in cloth" :key="i" elevation="0">
                  <v-layout wrap pa-2 class="nsregular" style="font-size:16px;">
                    <v-flex text-left xs2>{{ i+1 }}</v-flex>
                    <v-flex text-left xs5>
                      <span v-if="item.INdate">

                      {{ formatDate(item.INdate) }}
                      </span></v-flex>
                    <v-flex text-left xs5>
                      <span v-if="item.OUTdate">{{ formatDate(item.OUTdate) }}</span></v-flex>
                  </v-layout>
                  <v-divider></v-divider>
                </v-card>
              </v-flex>


              <v-flex xs12 v-else>
                <span>
                  No histroy found!
                </span>
              </v-flex>
            </v-layout>
             <v-pagination small color="#766BC0" total-visible="7" v-model="page" :length="Pagelength">
              </v-pagination>
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {  
        appLoading: false,
        cloth:[],
      ServerError: false,
       page: 1,
      Pagelength: 0,
         };
  },
  mounted() {
    this.getData();
  },    
   watch: {
    page() {
      // this.getData();
      this.getData();
    },
   },
 methods: {    
     getData() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/per/cloth/history",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          clothid: this.$route.query.id,
           page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.cloth = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);

          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year 
        + ' , ' + hours + ":" + minutes + " " + ampm
        
      return strTime;
    }, 
 },
};
</script>